import { env } from "env";

export function createKnowledgeBaseLinks(brand, baseUrl) {
  if (brand === "sonicwall") {
    const specifiedLinks = {
      smb_documentation: "https://www.sonicwall.com/support/",
      how_to_tutorials:
        "https://www.sonicwall.com/support/technical-documentation/?language=English&category=Cloud%20Edge",
      contact_us:
        "https://www.sonicwall.com/resources/trials-landing/cloud-edge-secure-access-free-30-day-trial/",
      blog: "https://blog.sonicwall.com/en-us/categories/cloud-security/",
    };
    for (let key of Object.keys(links.docs)) {
      links.docs[key] = specifiedLinks[key] || baseUrl;
    }
  }
}

const VALID_REGIONS = ["US", "EU", "CA"];

export function isValidRegionForSaaSApi(region) {
  return VALID_REGIONS.includes(region);
}

export const links = {
  whatsnew: "https://www.perimeter81.com/whats-new",
  scim: `https://api.${env.REACT_APP_DOMAIN}/api/scim`,
  dpa: "https://www.perimeter81.com/legal/dpa",
  events_request_prefix: "https://events",
  docs: {
    smb_documentation: "https://help.perimeter81.com/support/home",

    how_to_tutorials: "https://www.perimeter81.com/video#tutorials",

    blog: "https://www.perimeter81.com/blog/",

    network:
      "https://support.perimeter81.com/docs/360023404854-how-to-modify-your-network",

    application:
      "https://support.perimeter81.com/docs/360008864000-managing-application-access",

    policies:
      "https://support.perimeter81.com/docs/360025468933-how-to-manage-zero-trust-application-access-policies",

    certificates: "https://support.perimeter81.com/docs/360025534473",

    getting_started:
      "https://support.perimeter81.com/docs/360008863200-welcome-to-perimeter-81",

    guides: "https://support.perimeter81.com/docs",

    contact_support: "https://support.perimeter81.com/hc/en-us/requests/new",

    remove_workers_checklist:
      "https://www.perimeter81.com/start/remote-workers-checklist/",

    splunk: "https://support.perimeter81.com/docs/360011346320",

    azure_sentinel: "https://support.perimeter81.com/docs/360012680780",

    amazon_s3:
      "https://support.perimeter81.com/docs/360013111900-amazon-s3-integration",

    connectWise: "https://support.perimeter81.com/docs/connectwise",

    dns: "https://support.perimeter81.com/docs/dns-filtering",

    invite_member: "https://support.perimeter81.com/docs/how-do-i-invite-users",

    password_requirements:
      "https://support.perimeter81.com/docs/what-are-the-minimum-password-requirements-",

    roles:
      "https://support.perimeter81.com/docs/360022715853-how-do-i-assign-user-roles",

    unblocking_user_accounts:
      "https://support.perimeter81.com/docs/unblocking-user-accounts-database",

    generating_a_sign_out_code:
      "https://support.perimeter81.com/docs/generating-a-sign-out-code",

    member_devices:
      "https://support.perimeter81.com/docs/member-devices?highlight=manage%20device",

    managing_groups:
      "https://support.perimeter81.com/docs/360023404974-creating-user-groups",

    split_tunneling: "https://support.perimeter81.com/docs/split-tunneling",

    routes: "https://support.perimeter81.com/docs/routes",

    custom_dns:
      "https://support.perimeter81.com/docs/how-to-select-a-custom-dns",

    manage_access: "https://support.perimeter81.com/docs/segmenting-networks",

    add_tunnel:
      "https://support.perimeter81.com/docs/360004369613-access-cloud-infrastructure",

    ip_sec_tunnel: "https://support.perimeter81.com/docs/ipsec-tunnel",

    connector:
      "https://support.perimeter81.com/docs/configuring-a-wireguard-connector",

    activity: "https://support.perimeter81.com/docs/checking-network-activity",

    certificateValidationSecurityRisk:
      "https://support.perimeter81.com/docs/trusted-environment",

    auditLogs: "https://support.perimeter81.com/docs/admin-activity",

    infrastructureEventsLogs: "",

    usersActivityLogs:
      "https://support.perimeter81.com/docs/checking-network-activity",

    dpc: "https://support.perimeter81.com/docs/device-posture-check",

    identity_providers:
      "https://support.perimeter81.com/docs/360002504559-identity-providers",

    two_factor_auth:
      "https://support.perimeter81.com/docs/two-factor-authentication",

    openvpn: "https://support.perimeter81.com/docs/openvpn-tunnel",

    http:
      "https://support.perimeter81.com/docs/how-to-add-an-http-https-application",
    https:
      "https://support.perimeter81.com/docs/how-to-add-an-http-https-application",
    ssh: "https://support.perimeter81.com/docs/how-to-add-an-ssh-application",
    rdp: "https://support.perimeter81.com/docs/how-to-add-an-rdp-application",
    vnc: "https://support.perimeter81.com/docs/how-to-add-a-vnc-application",

    gsuite: "https://support.perimeter81.com/docs/google-services",
    okta: "https://support.perimeter81.com/docs/okta-identity-provider",
    okta_scim: "https://support.perimeter81.com/docs/okta",
    saml:
      "https://support.perimeter81.com/docs/360023570233-okta-identity-provider",
    azureAD: "https://support.perimeter81.com/docs/azure-active-directory-old",
    azureAD_scim: "https://support.perimeter81.com/docs/azure-active-directory",
    adLdap:
      "https://support.perimeter81.com/docs/360023405554-on-premises-active-directory",
    userProfiles:
      "https://support.perimeter81.com/v1/docs/user-groups-policies",
    npr: "https://support.perimeter81.com/docs/network-traffic-control",
    network_config:
      "https://support.perimeter81.com/docs/network-configuration",
    apiSupport: "https://support.perimeter81.com/docs/public-api-usage",
    monitor_dashboard:
      "https://support.perimeter81.com/docs/monitoring-dashboard",
    scim: "https://support.perimeter81.com/docs/scim",
    swg: "https://support.perimeter81.com/docs/secure-web-gateway",
    web_filter_rules:
      "https://support.perimeter81.com/docs/secure-web-gateway#web-filter-rules",
    bypass_rules:
      "https://support.perimeter81.com/docs/secure-web-gateway#bypass-rules",
    addresses: "https://support.perimeter81.com/docs/address-objects",
    services: "https://support.perimeter81.com/docs/services-objects",
    custom_urls: "https://support.perimeter81.com/docs/custom-urls-objects",
    programs_domains:
      "https://support.perimeter81.com/docs/secure-web-gateway#bypass-criteria",
    web_activity: "https://support.perimeter81.com/docs/web-activity",
    access_cloud_infra:
      "https://support.perimeter81.com/docs/access-cloud-infrastructure",
    support_access: "https://support.perimeter81.com/docs/support-access",
    download_agent:
      "https://support.perimeter81.com/docs/downloading-the-agent?highlight=agent%20installation",
    upload_ipsec_config_file:
      "https://support.perimeter81.com/v1/docs/uploading-tunnel-configuration-files",
    how_to_modify_your_network:
      "https://support.perimeter81.com/docs/360023404854-how-to-modify-your-network",

    rdpSecurityMode: "https://support.perimeter81.com/docs/rdp-security-mode",

    swg_certificates: "https://support.perimeter81.com/docs/swg-certificate",

    device_inventory: "https://support.perimeter81.com/docs/device-inventory",

    harmony_sase_datasheet:
      "https://www.checkpoint.com/resources/items/harmony-sase-datasheet",

    addingRegionsAndGateways:
      "https://support.perimeter81.com/docs/adding-regions-and-gateways",
  },
  imagesBaseUrl: process.env.REACT_APP_STATIC_ASSETS_URL,
  zta_learn_more:
    "https://support.perimeter81.com/docs/360008864000-managing-application-access",
  book_cp_demo:
    "https://www.perimeter81.com/demo-cp-portal?utm_source=cp&utm_medium=portal&utm_campaign=infinity",

  infinity_events_dashboard:
    "https://portal.checkpoint.com/dashboard/events/infinityevents#/dashboard/logs",
  infinity_event_support:
    "https://support.perimeter81.com/docs/infinity-events",
};
export const emptyStates = {
  DASHBOARD_EMPTY_STATE_JSON: `${links.imagesBaseUrl}/emptyState/dashboard/DashboardEmptyState.json`,
  NETWORK_EMPTY_STATE_JSON: `${links.imagesBaseUrl}/emptyState/networkEmptyState/NetworkEmptyState.json`,
  APPLICATION_EMPTY_STATE_JSON: `${links.imagesBaseUrl}/emptyState/applicationsEmptyState/ApplicationsEmptyState.json`,
  FIREWALL_EMPTY_STATE_JSON: `${links.imagesBaseUrl}/emptyState/firewallEmptyState/FirewallEmptyState.json`,
  DPC_EMPTY_STATE_JSON: `${links.imagesBaseUrl}/emptyState/DPCEmptyState/DPCEmptyState.json`,
  WELCOME_EMPTY_STATE_JSON: `${links.imagesBaseUrl}/emptyState/welcomePage/WelcomePage.json`,
};
export const browserSecurityJson = `${links.imagesBaseUrl}/emptyState/browserSecurity/browserSecurityJson.json`;
export const saasApiJson = `${links.imagesBaseUrl}/emptyState/saasApi/saasApiJson.json`;
export const getStartedJsonLink = `${links.imagesBaseUrl}/getStarted/getStarted.json`;

export const ldapConnectorAppLinks = {
  windows: "https://auth0.com/docs/connector/install",
  others: "https://auth0.com/docs/connector/install-other-platforms",
};

export const MAX_LIMIT_MEMBER_LICENSES = 150;

export const DEPLOYMENT_INSTANCES_LIMIT = 10;

export const STATIC_STORAGE_URL = "https://static.perimeter81.biz/api/files/";

export const icons = {
  DEFAULT_PROFILE:
    "https://static.perimeter81.biz/api/files/profile-picture-placeholder.svg",
  DEFAULT_NETWORK:
    "6a4f380370d56b43193171aababaf3bfa2f8002d7378b03db9207409f7734753_network_icon.png",
  DEFAULT_V4_NETWORK: "icon_NGN.png",
  DEFAULT_APPLICATION_HTTP: "applications/http.svg",
  DEFAULT_APPLICATION_HTTPS: "applications/https.svg",
  DEFAULT_APPLICATION_VNC: "applications/vnc.svg",
  DEFAULT_APPLICATION_RDP: "applications/rdp.svg",
  DEFAULT_APPLICATION_SSH: "applications/ssh.svg",
};

export const SORT_DIRECTION = {
  ASC: "asc",
  DESC: "desc",
};

export const DEFAULT_QUERY = {
  page: 1,
  limit: 50,
  field: "createdAt",
  dir: "desc",
  q: "",
};

export const STATUS = {
  INACTIVE: "init",
  ACTIVE: "active",
  CANCELLED: "cancelled",
  CANCEL_PLANNED: "non_renewing",
  TRIAL: "in_trial",
};

export const activeStatuses = [
  STATUS.ACTIVE,
  STATUS.CANCEL_PLANNED,
  STATUS.TRIAL,
];

export const INTEGRATIONS = {
  SPLUNK: "splunk",
  AZURE_SENTINEL: "azureSentinel",
  AMAZON_S3: "amazonS3",
  CONNECT_WISE: "connectWise",
};

export const ADDONS = {
  dnsFiltering: {
    name: "dnsFiltering",
    permission: "addon.dnsFiltering",
    additionalType: "addon-dnsFiltering",
  },
};

export const planNames = {
  enterprise: "enterprise",
  basic: "basic",
  standard: "standard",
  premium: "pro",
  premiumPlus: "premium-plus",
};

export const cycles = {
  month: "month",
  year: "year",
};

export const cyclesToSelect = [cycles.year, cycles.month];

export const cycleNames = {
  monthly: "monthly",
  yearly: "yearly",
  biennial: "biennial",
  triennially: "triennially",
};

export const subscriptionType = {
  trial: "trial",
};

export const idpTypes = {
  saml: "saml",
  gsuite: "gsuite",
  azureAD: "azureAD",
  okta: "okta",
  adLdap: "adLdap",
  connector: "connector",
  scim: "scim",
  database: "database",
  auth0: "auth0",
};

export const mfaNames = {
  guardian: "guardian",
  none: "none",
  googleAuthenticator: "google-authenticator",
  duo: "duo",
};

export const logsTypes = {
  systemActivity: "systemActivity",
  auditLogs: "auditLogs",
  usersActivityLogs: "usersActivityLogs",
  infrastructureEventsLogs: "infrastructureEventsLogs",
};

export const periodUnits = {
  minutes: "minutes",
  hours: "hours",
  days: "days",
};

export const DEFAULT_BADGE_PERIOD = [14, "days"];

export const tableFieldTypes = {
  truncatedText: "truncatedText",
  dateCreated: "dateCreated",
  userName: "userName",
  textWithIcon: "textWithIcon",
  indicatedText: "indicatedText",
  linkWithTooltip: "linkWithTooltip",
  textGroup: "textGroup",
};

export const httpHeaders = {
  accept: "Accept",
  auth: "Authorization",
  subAuth: "X-Sub-Authorization",
  contentType: "Content-Type",
  tenantId: "X-Tenant-Id",
};

export const ValueOperator = {
  equal: "equal",
  contain: "contain",
  notEqual: "notEqual",
};

export const sharedObjectTypes = {
  address: "address",
  service: "service",
  customUrl: "customUrl",
};

export const logPageIds = {
  infrastructure_events: "infrastructure_events",
  users_activity: "users_activity",
  admin_activity: "admin_activity",
};

export const networkFeatureNames = {
  FW_FQDN: "Firewall_FQDN",
  BW_CHART: "Bandwidth_chart",
  NATIVE_RDP: "Native_RDP",
  DYNAMIC_RDP_PROPERTIES: "Dynamic_RDP_properties",
};

export const v4 = "v4";

export const networkVersions = {
  V2: "0.8.0",
  V3: "3.8.0",
  V4: "4.0.0",
};

export const cpDemoTenantIds = [
  "f29d4b17-771d-4367-9315-5e6d24089e2c",
  "7e53a890-1ddf-4438-8c24-2240fa7fc9d5",
];

export const browserSecurityAddon = "addon-browserSecurity";
export const harmonyBrowseUrl =
  "https://portal.checkpoint.com/dashboard/browse#/overview";

export const saasApiUrl = `${env.REACT_APP_CP_BASENAME}`;

export const automaticLicenseFlow = Boolean(
  env.REACT_APP_USE_AUTOMATIC_SUBSCRIPTION_FLOW &&
    env.REACT_APP_USE_AUTOMATIC_SUBSCRIPTION_FLOW === "true"
);

export const checkpointLicenseInvalidCode = 700;
export const checkpointLicenseExpiredCode = 701;

export const mapCheckpointLicenseErrorCodeToMessage = {
  [checkpointLicenseInvalidCode]: "INVALID",
  [checkpointLicenseExpiredCode]: "EXPIRED",
};
