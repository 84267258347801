import ky, { ResponsePromise } from "ky";
import { env } from "env";
import { ApplicationName } from "types/CPIPAppIntegration";
interface PartnerSSO {
  token: string;
  tenantId: string;
}
export interface PartnerCheck {
  tenantExists: boolean;
  userExists: boolean;
  checkpointLicenseExists: boolean;
  chargebeeLicenseExists: boolean;
  checkpointLicenseErrors?: number;
  manuallyActivatingLicense?: boolean;
}

export interface CreateUser {
  tenantId: string;
  token: string;
}

export enum SubscriptionStatus {
  PENDING = "pending",
  FAIL = "fail",
  SUCCESS = "success",
}

interface HeartbeatPayload {
  sessionId: string;
}
export interface ValidateTenantSKUPayload {
  appList: ApplicationName[];
}

export type ValidateTenantSKUs = {
  [key in ApplicationName]: boolean;
};

export function validateTenantSKU(
  body: ValidateTenantSKUPayload
): Promise<{ checkRes: boolean[] }> {
  return ky
    .post(
      new Request(
        `${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/tenant/skusVerification`,
        {
          method: "post",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      )
    )
    .json();
}

export function heartbeat(body: HeartbeatPayload): void {
  ky.post(
    new Request(`${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/heartbeat`, {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
  ).json();
}

export function partnerCheck(): Promise<PartnerCheck> {
  return ky
    .get(
      new Request(
        `${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/tenant/status`,
        {
          credentials: "include",
        }
      )
    )
    .json();
}

export function partnerSSO(): Promise<PartnerSSO> {
  return ky
    .get(
      new Request(`${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/sso`, {
        credentials: "include",
      })
    )
    .json();
}

export function createUser(body: string): Promise<CreateUser> {
  return ky
    .post(
      new Request(`${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/users`, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      })
    )
    .json();
}

export function createWorkspace(body: {
  password: string;
  tenantId: string;
}): Promise<void> {
  return ky
    .post(
      new Request(`${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/tenant`, {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
    )
    .json();
}

export function checkSubscriptionStatus(): Promise<{
  status: SubscriptionStatus;
}> {
  return ky
    .get(
      new Request(
        `${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/subscription/status`,
        { credentials: "include" }
      ),
      {
        retry: 0,
      }
    )
    .json();
}

export function triggerTenantEvent(appName: string): ResponsePromise {
  return ky
    .post(
      new Request(
        `${env.REACT_APP_CP_MIDDLEWARE_BASE_URL}/partner/tenant/event`,{
          method: "post",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ appName }),
        }
      ),
      {
        retry: 0,
      }
    );
}
